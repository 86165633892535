import { DetailedOrder } from 'pages/orders/order/types';
import { CourierDates } from 'pages/orders/ordersList/types';
import { OrderMeasurements } from 'src/shared/types';
import api from '../api';

export interface OrdersRequestData {
  code?: number | string;
  price?: number | string;
  client_id?: number | string;
  created_at?: string;
  status?: number | string;
  statuses?: string[] | number[];
  pagination: {
    per_page: number;
    page: number;
  };
}

export interface LabelRequestData {
  id: number;
  type: string;
}

export interface LabelsRequestData {
  ids: number[];
}

export interface ReturnOrderReqData {
  id: number | string;
  description: string;
}

export interface OrderMeasureReqData {
  id: number | string;
  data: {
    dimensions: OrderMeasurements;
  };
}

export const getOrdersList = (data: OrdersRequestData) => api.post('/orders', data).then(response => response.data);

export const getOrder = (id: string | number) => api.get(`/order/${id}`).then(response => response.data);

export const editOrderMeasurements = (data: OrderMeasureReqData) =>
  api.post(`/order/${data.id}`, data.data).then(response => response.data);

export const getOrderPromise = (id: string) => api.get(`/order/${id}`);

export const moveOrderToAssembly = (id: string): Promise<DetailedOrder> =>
  api.post(`/order/${id}/assembly`).then(response => response.data);

export const createDelivery = (id: number | string) =>
  api.post(`/order/${id}/create-delivery`).then(response => response.data);

export const sendToDelivery = (ids: number[]) =>
  api.post(`/orders/send-to-delivery`, { ids }).then(response => response.data);

export const getOrdersLabel = (data: LabelRequestData) =>
  api.get(`/order/${data.id}/${data.type}`).then(response => response.data);

export const getOrdersLabels = (data: LabelsRequestData) =>
  api.post('/orders/label', data).then(response => response.data);

export const retryDebit = (id: number) => api.post(`/order/${id}/debit`).then(response => response.data);

export const callCourier = (data: CourierDates | null) =>
  api.post(`/orders/call-courier`, data).then(response => response.data);

export const takeDelivery = (ids: number[]) =>
  api.post(`/orders/take-delivery`, { ids }).then(response => response.data);

export const cancelOrder = (id: string) => api.post(`/order/${id}/cancel`).then(response => response.data);

export const returnOrder = ({ id, description }: ReturnOrderReqData) =>
  api.post(`/order/${id}/return`, { description }).then(response => response.data);

export const getOrderTransactions = (id: string) =>
  api.get(`/order/${id}/transactions`).then(response => response.data);

export const getOrderStatuses = () => api.get('/order/statuses').then(response => response.data);

export const getAssemblyLabel = (id: string) => api.get(`/order/${id}/assembly/label`).then(response => response.data);
