import { Menu, Col, Row } from 'antd';
import { Route, Routes, Link, useLocation } from 'react-router-dom';

import { Synonyms } from './synonyms';
import { Tokens } from './tokens';
import { Pavilions } from './pavilions';
import { Exceptions } from './exceptions';
import { Commission } from './commission';
import { Captcha } from './captcha';
import { Logs } from './logs';
import { AdminsLogs } from './logs/adminsLogs';
import { Categories } from './categories';
import { Sizes } from './sizes';
import { Documents } from './documents';
import { ParserTime } from './parserTime';
import { Sources } from './sources';
import styles from './Settings.module.less';
import { Splitting } from './splitting';
import { ChatTest } from './chatTest';

export const SettingsPage = () => {
  const location = useLocation();

  return (
    <section>
      <h1 className={styles.title}>Настройки</h1>

      <Row className={styles.body}>
        <Col className={styles.itemMenu}>
          <Menu selectedKeys={[location.pathname]} defaultOpenKeys={['subMenu']} mode="inline">
            <Menu.SubMenu key="subMenu" title="Логирование">
              <Menu.Item key="/settings/logs">
                <Link to="/settings/logs">Парсер</Link>
              </Menu.Item>
              <Menu.Item key="/settings/logs/adminsLogs">
                <Link to="/settings/logs/adminsLogs">Сотрудники</Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key="/settings/commission" className={styles.item}>
              <Link to="/settings/commission">Установка комиссии</Link>
            </Menu.Item>
            <Menu.Item key="/settings/captcha" className={styles.item}>
              <Link to="/settings/captcha">Капча</Link>
            </Menu.Item>
            <Menu.Item key="/settings/parserTime" className={styles.item}>
              <Link to="/settings/parserTime">Парсер</Link>
            </Menu.Item>
            <Menu.Item key="/settings/pavilions" className={styles.item}>
              <Link to="/settings/pavilions">Павильоны</Link>
            </Menu.Item>
            <Menu.Item key="/settings/synonyms" className={styles.item}>
              <Link to="/settings/synonyms">Синонимы</Link>
            </Menu.Item>
            <Menu.Item key="/settings/exceptions" className={styles.item}>
              <Link to="/settings/exceptions">Исключения</Link>
            </Menu.Item>
            <Menu.Item key="/settings/tokens" className={styles.item}>
              <Link to="/settings/tokens">Токены</Link>
            </Menu.Item>
            <Menu.Item key="/settings/sizes" className={styles.item}>
              <Link to="/settings/sizes">Размеры</Link>
            </Menu.Item>
            <Menu.Item key="/settings/category" className={styles.item}>
              <Link to="/settings/category">Категории</Link>
            </Menu.Item>
            <Menu.Item key="/settings/documents" className={styles.item}>
              <Link to="/settings/documents">Документы</Link>
            </Menu.Item>
            <Menu.Item key="/settings/splitting" className={styles.item}>
              <Link to="/settings/splitting">Сплитование</Link>
            </Menu.Item>
            <Menu.Item key="/settings/sources" className={styles.item}>
              <Link to="/settings/sources">Источники</Link>
            </Menu.Item>
            {/* <Menu.Item key="/settings/chatTest" className={styles.item}>
              <Link to="/settings/chatTest">Тестирование чата</Link>
            </Menu.Item> */}
          </Menu>
        </Col>
        <Col className={styles.itemBody}>
          <Routes>
            <Route index element={<Pavilions />} />
            <Route path="/synonyms" element={<Synonyms />} />
            <Route path="/exceptions" element={<Exceptions />} />
            <Route path="/commission" element={<Commission />} />
            <Route path="/captcha" element={<Captcha />} />
            <Route path="/parserTime" element={<ParserTime />} />
            <Route path="/logs/adminsLogs" element={<AdminsLogs />} />
            <Route path="/logs" element={<Logs />} />
            <Route path="/tokens" element={<Tokens />} />
            <Route path="/pavilions" element={<Pavilions />} />
            <Route path="/category" element={<Categories />} />
            <Route path="/documents" element={<Documents />} />
            <Route path="/sizes" element={<Sizes />} />
            <Route path="/splitting" element={<Splitting />} />
            <Route path="/sources" element={<Sources />} />
            <Route path="/chatTest" element={<ChatTest />} />
          </Routes>
        </Col>
      </Row>
    </section>
  );
};
