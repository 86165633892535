import { Link, useLocation } from 'react-router-dom';
import { Avatar, Tooltip } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import { maxTextSymbols } from './config';
import { NameProductCardType } from './types';
import styles from './NameProductCard.module.less';

interface NameCardProps {
  data?: NameProductCardType;
}

export const NameProductCard = ({ data }: NameCardProps) => {
  const location = useLocation();
  const name =
    data?.name && data.name.length > maxTextSymbols.name ? `${data.name.slice(0, maxTextSymbols.name)}...` : data?.name;
  const description =
    data?.description && data.description.length > maxTextSymbols.description
      ? `${data.description.slice(0, maxTextSymbols.description)}...`
      : data?.description;

  return (
    <div className={styles.wrapper}>
      <Avatar
        shape="square"
        size="large"
        icon={<PictureOutlined />}
        src={data?.images?.[0]}
        className={styles.avatar}
      />
      <div>
        <p className={styles.name}>
          <Tooltip
            overlayStyle={{ position: 'fixed' }}
            title={data && data?.name.length > maxTextSymbols.name ? data?.name : ''}>
            {data && name ? (
              <Link to={`/product/${data.id}`} state={{ prevPath: location.pathname }}>
                {name}
              </Link>
            ) : (
              '-'
            )}
          </Tooltip>
        </p>
        <p className={styles.description}>
          <Tooltip
            overlayStyle={{ position: 'fixed' }}
            title={data && data?.description.length > maxTextSymbols.description ? data?.description : ''}>
            {data && description ? description : '-'}
          </Tooltip>
        </p>
      </div>
    </div>
  );
};
